<template>
  <v-app>
    <v-row align="center" justify="center">
      <v-col xs="12" sm="8" md="4" class="px-4">
        <v-card class="elevation-12" outlined tile>
          <v-toolbar dark color="blue">
            <v-toolbar-title>Insert your credentials</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text class="mb-0">
            <v-alert v-if="loginErro" color="red" outlined type="error">{{ loginErro }}</v-alert>
            <v-form ref="form">
              <v-text-field
                  :rules="requiredRules"
                  prepend-icon="person"
                  name="login"
                  label="E-mail"
                  type="text"
                  v-model="username"
                  v-on:keyup.enter="validateForm"></v-text-field>
              <v-text-field
                  :rules="requiredRules"
                  id="password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  v-model="password"
                  v-on:keyup.enter="validateForm"></v-text-field>
            </v-form>
            <div class="text-center">
              <v-btn plain class="text-decoration-underline" @click.prevent="$router.push({name: 'request_reset'})">
                Reset Password
              </v-btn>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="showLoader" :loading="showLoader"
                   class="theme--dark btn btn-primary submit-btn" @click.prevent="validateForm">
              Login
            </v-btn>
            <v-dialog v-model="showLoader" persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Validating your credentials...
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import axios from "axios";
// import store from "@/vuex";
// import router from "@/router";

export default {
  data: function () {
    return {
      username: '',
      password: '',
      serverErrorMessage: '',
      showLoader: false,
      loginErro: null,
      requiredRules: [(v) => !!v && v.trim() !== ' ' || "Required"]
    }
  },
  methods: {
    validateForm: async function () {
      // validate form
      if (!this.$refs.form.validate()) {
        this.$refs.form.validate();
        return;
      }

      this.showLoader = true;

      // request
      await this.axios.post(process.env.VUE_APP_API_URL + "/wholesaler/login", {
        email: this.username,
        password: this.password
      }).then(response => {
        console.log(response.data.token);
        window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.token;
        this.$store.commit('setAuthenticated', true);
        this.$store.commit('setUser', response.data.user);
        this.$store.commit('setSessionToken', 'Bearer ' + response.data.token)
        this.$router.push({name: 'dashboard'})

      }).catch(error => {
        this.$store.commit('setAuthenticated', false);
        this.loginErro = 'Ups...  Connection failed, try again later please!'
        console.log(error.response)
      });

      this.showLoader = false;
    },
    checkLoggedIn() {
      axios.defaults.headers.common['Authorization'] = this.$store.state.sessionToken;
      axios.get(process.env.VUE_APP_API_URL + "/wholesalers/authenticated")
          .then( response => {
            this.$store.commit('setAuthenticated', true);
            this.$store.commit('setUser', response.data);
            this.$router.push({name: 'dashboard'});
            return true;
          }).catch(() => {
        this.$store.commit('setSessionToken', null);
        this.$store.commit('setAuthenticated', false);
        return false;
      });
    }
  },
  created() {
    if (this.$route.query.token) {
      window.axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.$route.query.token;
      this.$store.commit('setSessionToken', 'Bearer ' + this.$route.query.token);
      this.$store.commit('setAuthenticated', true);
      this.$router.push({name: 'dashboard', query: {token: this.$route.query.token}})
    }
    this.checkLoggedIn();
  }
}
</script>
