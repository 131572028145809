<template>
  <v-navigation-drawer
          v-model="drawer"
          app
          clipped
  >
    <v-list dense>

      <v-list-item to="/dashboard/resellers">
        <v-list-item-action >
          <v-icon>mdi-ticket-confirmation</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Cupons
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/wholesalers">
        <v-list-item-action >
          <v-icon>mdi-cash-multiple</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Stripe Account
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/dashboard/comissions">
        <v-list-item-action >
          <v-icon>mdi-file-download</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Download Contract
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>



    </v-list>

  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
    items: [
      { icon: 'trending_up', text: 'Most Popular' },
      { icon: 'subscriptions', text: 'Subscriptions' },
      { icon: 'history', text: 'History' },
      { icon: 'featured_play_list', text: 'Playlists' },
      { icon: 'watch_later', text: 'Watch Later' },
    ],
    items2: [
      { picture: 28, text: 'Joseph' },
      { picture: 38, text: 'Apple' },
      { picture: 48, text: 'Xbox Ahoy' },
      { picture: 58, text: 'Nokia' },
      { picture: 78, text: 'MKBHD' },
    ],
  }),
    computed:{
     exportUserDataURL(){
     return  process.env.VUE_APP_API_URL + '/export/userdata/?sessionToken='+ this.$store.state.sessionToken
    }
  },
  created() {
    window.getApp.$on("APP_DRAWER_TOGGLED", () => {
      this.drawer = !this.drawer;
    });
  },
};
</script>
