<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-btn block outlined @click="reloadPage()">
      Rafraîchir
      <v-icon
          right
      >
        mdi-refresh
      </v-icon>
    </v-btn>
    <v-card
        outlined
        tile>
      <v-container grid-list-lg>
        <v-layout row wrap>

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-card-text class="">
                  <p style="color:black;"><strong>Lien à transmettre pour l'inscription de vos revendeurs : </strong>
                    <a :href="getTechSecurityLink+'/partner/'+ user.name_url" target="_blank">{{
                        getTechSecurityLink
                      }}/partenaire/{{ user.name_url }}</a>
                  </p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md6 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-card-text class="">
                  <v-alert type="success" v-show="success">
                    Password changed successfully.
                  </v-alert>
                  <v-btn block outlined @click="openChangePassword" v-if="!changingPassword">
                    Change Password
                  </v-btn>
                  <div v-if="changingPassword">
                    <v-toolbar
                        class="text-h5"
                        color="primary"
                        dark
                    >
                      Change Password
                    </v-toolbar>
                    <v-card-text class="pa-4 pb-0">
                      <v-alert type="error" v-show="hasServerErrors">
                        <span v-for="error in serverErrors" :key="error">{{ error }}</span>
                      </v-alert>
                      <form>
                        <v-text-field
                            v-model="model.current_password"
                            :append-icon="showCurrent ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showCurrent ? 'text' : 'password'"
                            :error-messages="currentPasswordErrors"
                            label="Current Password"
                            required
                            @input="$v.model.current_password.$touch()"
                            @blur="$v.model.current_password.$touch()"
                            outlined
                            dense
                            @click:append="showCurrent = !showCurrent"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.password"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showPassword ? 'text' : 'password'"
                            :error-messages="passwordErrors"
                            label="Password"
                            required
                            @input="$v.model.password.$touch()"
                            @blur="$v.model.password.$touch()"
                            outlined
                            dense
                            @click:append="showPassword = !showPassword"
                        ></v-text-field>
                        <v-text-field
                            v-model="model.password_confirmation"
                            :append-icon="showConfirmation ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="showConfirmation ? 'text' : 'password'"
                            :error-messages="passwordConfirmationErrors"
                            label="Password confirmation"
                            required
                            @input="$v.model.password_confirmation.$touch()"
                            @blur="$v.model.password_confirmation.$touch()"
                            outlined
                            dense
                            @click:append="showConfirmation = !showConfirmation"
                        ></v-text-field>
                      </form>
                    </v-card-text>
                    <v-card-actions class="pr-4">
                    <v-spacer></v-spacer>
                      <v-btn
                          color="primary"
                          text
                          @click="closeChangePassword();"
                      >
                        Cancel
                      </v-btn>
                    <v-btn
                        class="ml-4 float-end"
                        color="primary"
                        @click="submit()"
                    >
                      submit
                    </v-btn>
                  </v-card-actions>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>

          <v-flex xs12 md12 class="">
            <v-flex xs12 class="pa-0">
              <v-card style="">
                <v-toolbar
                    class="text-h5"
                    color="primary"
                    dark
                >
                 Resellers
                </v-toolbar>
                <v-card-text class="pa-0">
                  <v-card class="pa-4"
                          outlined
                          tile>
                    <v-container grid-list-lg>
                      <v-layout row wrap>
                        <v-flex xs6 md3 lg2 class="pb-0">
                          <v-select
                              :items="statusList"
                              item-text="text"
                              item-value="value"
                              v-model="status"
                              label="Status"
                              color="blue"
                              multiple
                          ></v-select>
                        </v-flex>

                        <v-spacer></v-spacer>
                        <v-flex xs12 md5 lg4 class="pb-0">
                          <v-text-field
                              v-model="search"
                              append-icon="search"
                              label="Search"
                              color="blue"
                              dense
                              clearable
                          ></v-text-field>
                        </v-flex>

                        <v-flex xs12>
                          <v-data-table :headers="headers"
                                        :items="resellers"
                                        :server-items-length="total"
                                        :loading="tableLoading"
                                        :options.sync="options"
                                        :search="search"
                                        :footer-props="{
                                      itemsPerPageOptions: rowsPerPageItems,
                                      itemsPerPageText: 'Items per page:'}"
                                        class="elevation-1 nowrap" loading-text="Loading...">

                            <template v-slot:item.name="{ item }">
                              {{`${item.company} - `}}<strong>{{item.name}}</strong>
                              <v-btn icon color="primary" @click="openInfoDialog(item)">
                                <v-icon dark>
                                  mdi-information-variant
                                </v-icon>
                              </v-btn>
                            </template>

                            <template v-slot:item.cupons="{ item }">
                              <v-btn icon color="primary" @click="openCuponDialog(item)">
                                <v-icon dark>
                                  mdi-eye
                                </v-icon>
                              </v-btn>
                            </template>

                            <template v-slot:item.active_subs="{ item }">
                              {{ item.active_subs }}
                            </template>

                            <template v-slot:item.inactive_subs="{ item }">
                              {{ item.inactive_subs }}
                            </template>

                            <template v-slot:item.retention="{ item }">
                              <v-chip
                                  :color="getColor(resellerTotalRate(item))"
                                  dark
                              >
                                {{ resellerTotalRate(item) }}%
                              </v-chip>
                              <v-btn icon color="primary" @click="openSubscriptionsDialog(item)">
                                <v-icon dark>
                                  mdi-information-variant
                                </v-icon>
                              </v-btn>
                            </template>

                            <template v-slot:item.volume_subscription_sales="{item}">
                              {{ item.volume_subscription_sales.toFixed(2) }} €
                            </template>

                            <template v-slot:item.total_paid_comissions="{item}">
                              {{ item.total_paid_comissions.toFixed(2) }} €
                            </template>

                            <template v-slot:item.balance="{item}">
                              {{ item.balance.toFixed(2) }} €
                            </template>

                            <template v-slot:item.last_payment="{ item }">

                              {{ formatDate(item.last_payment) }}
                            </template>
                            <template v-slot:item.status="{ item }">
                              <v-chip
                                  :color="item.status === 'R'?'blue':item.status === 'B'?'red':'green'"
                                  dark
                                  label
                              >
                                {{ item.status === 'R' ? 'Registered' : item.status === 'B' ? 'Blocked' : 'Active' }}
                              </v-chip>
                            </template>
                          </v-data-table>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-flex>


        </v-layout>
      </v-container>
    </v-card>
    <v-dialog
        v-model="infoDialog"
        scrollable
        width="700"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          {{ selectedItem.name }} Details
        </v-toolbar>

        <v-card-text class="pt-5">
          <v-simple-table>
            <template v-slot:default>
              <tbody>
              <tr>
                <td><strong>Name</strong></td>
                <td>{{ selectedItem.name }}</td>
              </tr>
              <tr>
                <td><strong>Company</strong></td>
                <td>{{ selectedItem.company }}</td>
              </tr>
              <tr>
                <td><strong>Siren</strong></td>
                <td>{{ selectedItem.siren }}</td>
              </tr>
              <tr>
                <td><strong>Phone</strong></td>
                <td>{{ selectedItem.phone }}</td>
              </tr>
              <tr>
                <td><strong>E-mail</strong></td>
                <td>{{ selectedItem.email }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="infoDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="subscriptionsDialog"
        scrollable
        width="700"
    >

      <v-card v-if="selectedItem">
        <v-toolbar
            class="text-h5"
            color="primary"
            dark>
          {{ selectedItem.name }} Subscriptions Details
        </v-toolbar>

        <v-card-text class="pt-5">
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Active
                </th>
                <th class="text-left">
                  Inactive
                </th>
                <th class="text-left">
                  Retention Rate
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(item, index) in selectedComissionStats"
                  :key="index"
              >
                <td><strong>{{ item.name }}</strong></td>
                <td>{{ item.active_comissions }}</td>
                <td>{{ item.inactive_comissions }}</td>
                <td>
                  <v-chip
                      :color="getColor(item.rate)"
                      dark
                  >
                    {{ item.rate }}%
                  </v-chip>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td><strong>Total</strong></td>
                <td>
                  <v-chip
                      :color="getColor(resellerTotalRate(selectedItem))"
                      dark
                  >
                    {{
                      resellerTotalRate(selectedItem)
                    }}%
                  </v-chip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="subscriptionsDialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// const Swal = require('sweetalert2')
const moment = require('moment');
import {validationMixin} from 'vuelidate'
import {required, sameAs} from 'vuelidate/lib/validators'

export default {
  name: 'Main',
  mixins: [validationMixin],
  data: () => ({
    loading: false,
    total: 0,
    options: {
      itemsPerPage: 10,
      page: 1,
    },
    search: '',
    rowsPerPageItems: [5, 10, 20],
    headers: [
      {
        text: 'Name',
        align: 'left',
        value: 'name',
        sortable: true,
      },
      // {
      //   text: 'Cupons',
      //   align: 'left',
      //   value: 'cupons',
      //   sortable: false,
      // },
      {
        text: 'Active Subscriptions',
        align: 'left',
        value: 'active_comissions',
        sortable: true,
      },
      {
        text: 'Inactive Subscriptions',
        align: 'left',
        value: 'inactive_comissions',
        sortable: true,
      },
      {
        text: 'Retention rate',
        align: 'left',
        value: 'retention',
        sortable: false,
      },
      {
        text: 'Current volume',
        align: 'left',
        value: 'volume_subscription_sales',
        sortable: false,
      },
      // {
      //   text: 'Total received',
      //   align: 'left',
      //   value: 'total_paid_comissions',
      //   sortable: true,
      // },
      // {
      //   text: 'Last payment date',
      //   align: 'left',
      //   value: 'last_payment',
      //   sortable: true,
      // },
      // {
      //   text: 'Balance',
      //   align: 'left',
      //   value: 'balance',
      //   sortable: true,
      // },
      {
        text: 'Status',
        align: 'left',
        value: 'status',
      }
    ],
    resellers: [],
    statusList: [
      {value: 'R', text: 'Registered'},
      {value: 'A', text: 'Active'},
      {value: 'B', text: 'Blocked'}
    ],
    status: '',
    tableLoading: false,
    subscriptionsDialog: false,
    infoDialog: false,
    selectedItem: null,
    selectedComissionStats: null,
    model: {
      current_password: '',
      password: '',
      password_confirmation: '',
    },
    hasClientErrors: false,
    hasServerErrors: false,
    serverErrors: {},
    submitAttempt: false,
    success: false,
    showCurrent: false,
    showPassword: false,
    showConfirmation: false,
    changingPassword: false
  }),
  validations: {
    model: {
      current_password: {
        required
      },
      password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    }
  },
  watch: {
    options: {
      handler() {
        this.getResellers()
      },
      deep: true,
    },
    search() {
      this.options.page = 1;
      this.getResellers()
    },
    status() {
      this.options.page = 1;
      this.getResellers()
    },

  },
  computed: {
    user() {
      console.log("this.$store.state: ", this.$store.state);
      return this.$store.state.user;
    },
    getTechSecurityLink() {
      return process.env.VUE_APP_TECHSECURITY_URL
    },
    currentPasswordErrors() {
      const errors = []
      if (!this.$v.model.current_password.$dirty) return errors
      !this.$v.model.current_password.required && errors.push('Current password is required')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.model.password.$dirty) return errors
      !this.$v.model.password.required && errors.push('Password is required')
      return errors
    },
    passwordConfirmationErrors() {
      const errors = []
      if (!this.$v.model.password_confirmation.$dirty) return errors;
      !this.$v.model.password_confirmation.sameAsPassword && errors.push('Passwords must match')
      return errors
    },
  },
  methods: {
    openChangePassword(){
      this.success = false;
      this.clear();
      this.changingPassword = true;
    },
    closeChangePassword(){
      this.clear();
      this.changingPassword = false;
    },
    submit() {
      this.success = false;
      this.submitAttempt = true;
      this.hasClientErrors = false;
      this.hasServerErrors = false;
      this.serverErrors = {};
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const data = {
          current_password: this.model.current_password,
          new_password: this.model.password,
          new_password_confirmation: this.model.password_confirmation,
        };

        this.axios.post(process.env.VUE_APP_API_URL + "/changePassword", data)
            .then(() => {
              this.success = true;
              this.closeChangePassword();
            }).catch(error => {
              this.serverErrors = error.response.data.errors;
              this.hasServerErrors = true;
            }
        );
      } else {
        this.hasClientErrors = true;
      }
    },
    clear() {
      this.$v.$reset();
      this.model.current_password = '';
      this.model.password = '';
      this.model.password_confirmation = '';
    },
    reloadPage() {
      window.location.reload();
    },
    getResellers() {
      this.tableLoading = true;
      const sortBy = this.options.sortBy && this.options.sortBy.length ? '&order=' +  this.options.sortBy[0] +  '&desc=' + this.options.sortDesc[0] : '';
      this.axios.get(process.env.VUE_APP_API_URL + "/wholesaler/resellers?name=" + this.search + "&status=" + this.status + "&items_per_page=" + this.options.itemsPerPage + "&page=" + this.options.page + sortBy)
          .then(response => {
            this.total = response.data.meta.total
            this.resellers = response.data.data;
            this.tableLoading = false;
          }).catch(error => {
            this.tableLoading = false;
            console.log(error.response)
          }
      );
    },
    totalRate(resellers) {
      let total = 0
      resellers.map(resseler => {
        total += resseler.rate
      })

      return total ? total / resellers.length : total
    },
    resellerTotalRate(reseller) {
      return ((reseller.active_comissions + reseller.inactive_comissions) === 0 ? 0 : (reseller.active_comissions * 100) / (reseller.active_comissions + reseller.inactive_comissions)).toFixed(2);
    },
    getColor(val) {
      if (val > 74) {
        return 'green'
      }
      return val > 49 ? 'orange' : 'red'
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    openInfoDialog(item) {
      this.selectedItem = item;
      this.infoDialog = true;
    },
    openSubscriptionsDialog(item) {
      this.loading = true;
      this.axios.get(process.env.VUE_APP_API_URL + "/wholesaler/comissionStats/" + item.id)
          .then(response => {
            this.selectedComissionStats = response.data.data
            this.selectedItem = item;
            this.subscriptionsDialog = true;
            this.loading = false;
            console.log("Commissions Stats", response.data.data)
          }).catch(error => {
            this.loading = false;
            console.log(error.response)
          }
      );

    },
  },
  mounted() {

  },
  created() {
    this.getResellers();
  }

}
</script>

<style>

</style>