<template>
  <v-app>
    <v-row align="center" justify="center">
      <v-col xs="12" sm="8" md="4" class="px-4">
        <v-card class="elevation-12" outlined tile>
          <v-toolbar dark color="blue">
            <v-toolbar-title>Insert your email</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="loginErro" color="red" outlined type="error">{{ loginErro }}</v-alert>
            <v-alert v-if="success" color="green" outlined type="success">Password reset email sent!</v-alert>
            <v-form ref="form">
              <v-text-field
                  :rules="requiredRules"
                  prepend-icon="person"
                  name="login"
                  label="E-mail"
                  type="text"
                  v-model="username"
                  v-on:keyup.enter="validateForm"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="showLoader" :loading="showLoader"
                   class="theme--dark btn btn-primary submit-btn" @click.prevent="validateForm">
              Reset Password
            </v-btn>
            <v-dialog v-model="showLoader" persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Sending email...
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>

// import store from "@/vuex";
// import router from "@/router";

export default {
  data: function () {
    return {
      username: '',
      serverErrorMessage: '',
      showLoader: false,
      loginErro: null,
      success: false,
      requiredRules: [(v) => !!v && v.trim() !== ' ' || "Required"]
    }
  },
  methods: {
    validateForm: async function () {
      this.success = false;
      this.loginErro = false;
      // validate form
      if (!this.$refs.form.validate()) {
        this.$refs.form.validate();
        return;
      }

      this.showLoader = true;

      // request
      await this.axios.post(process.env.VUE_APP_API_URL + "/wholesaler/forgot-password", {
        email: this.username,
      }).then(() => {
        this.success = true;
      }).catch(error => {
        this.loginErro = error.response.data.message
        console.log(error.response)
      });

      this.showLoader = false;
    },
  },
  created() {
  }
}
</script>
