export default { 
      // STORE MUTATIONS
      setSessionToken(state, data){
            state.sessionToken = data
      },
      setAuthenticated(state, value) {
            state.authenticated = value;
      },
      setStripeLink(state, value) {
            state.stripe_link = value;
      },
      setUser(state, value) {
            state.user = value;
      },
}