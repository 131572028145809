<template>
  <div>
    <toolbar></toolbar>

    <v-main>
      <v-container grid-list-xl fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  created() {

  },
};
</script>
