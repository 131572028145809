import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../vuex/index';
import auth from "../middleware/auth";
// import not_auth from "../middleware/not_auth";

Vue.use(VueRouter);

import Login from '../components/login/login';
import ResetPassword from '../components/login/reset';
import ForgotPassword from '../components/login/request_reset';
import DashboardLayout from '../components/template/DashboardLayout';
import Main from '../components/dashboard/Main';

// ERROR COMPONENTS 
import notAuthorized from '../components/error/notAuthorized'
import axios from 'axios'

/*PREVENT DUPLICATED ROUTE ERROR*/
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => console.log("ROUTE ERROR"));
}

/*ROUTES*/
const routes = [
    {
        path: '/dashboard',
        component: DashboardLayout,
        name: 'dashboard',
        redirect: '/dashboard/main',
        meta: {
            middleware: auth,
        },
        children: [
            {
                path: 'main',
                name: 'main',
                component: Main,
                meta: {
                    middleware: auth,
                },
            },

        ]
    },
    {
        path: '/',
        component: DashboardLayout,
        name: 'entry',
        redirect: '/login',
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
    },
    {
        path: '/reset-password',
        component: ResetPassword,
        name: 'reset',
    },
    {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'request_reset'
    },
    {
        path: '/Forbidden',
        component: notAuthorized,
        name: 'Forbidden',
    }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes
});

function checkLoggedIn() {
    axios.defaults.headers.common['Authorization'] = store.state.sessionToken;
    axios.get(process.env.VUE_APP_API_URL + "/wholesalers/authenticated")
        .then(() => {
            store.commit('setAuthenticated', true);
            return true;
        }).catch(() => {
        store.commit('setSessionToken', null);
        store.commit('setAuthenticated', false);
        store.commit('setStripeLink', null);
        router.push({name: 'login'});
        return false;
    });
}

// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware});
    };
}

router.beforeEach(async (to, from, next) => {
    if (to.meta.middleware) {
        checkLoggedIn();
        const middleware = Array.isArray(to.meta.middleware)
            ? to.meta.middleware
            : [to.meta.middleware];

        const context = {
            from,
            next,
            router,
            to,
        };
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware});
    }

    return next();
});

export default router;