<template>
  <v-app>
    <v-row align="center" justify="center">
      <v-col xs="12" sm="8" md="4" class="px-4">
        <v-card class="elevation-12" outlined tile v-if="!success">
          <v-toolbar dark color="blue">
            <v-toolbar-title>Insert your new password</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="loginErro" color="red" outlined type="error">{{ loginErro }}</v-alert>
            <v-form ref="form">
              <v-text-field
                  :rules="requiredRules"
                  id="password"
                  prepend-icon="lock"
                  name="password"
                  label="Password"
                  type="password"
                  v-model="password"
                  v-on:keyup.enter="validateForm"></v-text-field>
              <v-text-field
                  :rules="requiredRules"
                  id="password_confirmation"
                  prepend-icon="lock"
                  name="password_confirmation"
                  label="Password Confirmation"
                  type="password"
                  v-model="password_confirmation"
                  v-on:keyup.enter="validateForm"></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="showLoader" :loading="showLoader"
                   class="theme--dark btn btn-primary submit-btn" @click.prevent="validateForm">
              Reset
            </v-btn>
            <v-dialog v-model="showLoader" persistent width="300">
              <v-card color="primary" dark>
                <v-card-text>
                  Reseting your password...
                  <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>

        <v-card class="elevation-12" outlined tile v-else>
          <v-card-text>
            <div class="text-center">
              <v-alert v-if="success" color="green" outlined type="success">Password reseted with success!</v-alert>
              <v-btn class="theme--dark btn btn-primary submit-btn" @click.prevent="$router.push({name: 'login'})">
                Login
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>

// import store from "@/vuex";
// import router from "@/router";

export default {
  data: function () {
    return {
      success: false,
      password: '',
      password_confirmation: '',
      serverErrorMessage: '',
      showLoader: false,
      loginErro: null,
      requiredRules: [(v) => !!v && v.trim() !== ' ' || "Required"]
    }
  },
  methods: {
    validateForm: async function () {
      // validate form
      this.success = false;
      if (!this.$refs.form.validate()) {
        this.$refs.form.validate();
        return;
      }

      this.showLoader = true;

      // request
      await this.axios.post(process.env.VUE_APP_API_URL + "/wholesaler/reset-password", {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then(() => {
        console.log("aqui");
        this.success = true;
        this.showLoader = false;
      }).catch(error => {
        console.log("aqu1i");
        this.showLoader = false;
        if(error){
          this.loginErro =  error.response?.data?.message || error.response
        }

        this.showLoader = false;
        console.log(error.response)
      });


    },
  },
  created() {
  }
}
</script>
